import regionsList from '@/config/regions'
import Cookies from 'js-cookie'
import localizedTexts from '@/public/localized-texts.json'

export const setLocaleToCookies = (locale) => {
  const selectedLocale = regionsList.default.find((country) => country.locale === locale.replace('_', '-'))
  console.debug('selectedLocale', selectedLocale)
  Cookies.set('currentSiteGeoData', `Gore${selectedLocale.region}@${selectedLocale.locale}`, {
    expires: 7,
  })
}

export const getDevModeCookies = () => {
  const devCookies = parseInt(Cookies.get('devMode')) || 0
  return devCookies
}

export const trans = (key, defaultText) => {
  let text = localizedTexts.find((localizedText) => localizedText?.fields?.key == key)

  try {
    if (text) {
      if (
        text?.fields?.sublocaleOverride &&
        text?.fields?.sublocaleOverride[process.env.NEXT_PUBLIC_SITE_REGION]
      ) {
        return text?.fields?.sublocaleOverride[process.env.NEXT_PUBLIC_SITE_REGION]
      }

      return text?.fields?.text
    }
  } catch (error) {
    console.error('trans error')
  }

  return defaultText
}

export const transGetYourJunk = (key, defaultText) => {
  let text = localizedTextGetYourJunk.find((localizedText) => localizedText.fields.key == key)

  if (text) {
    return text.fields.text
  }

  return defaultText
}
